import React from 'react';
import './NavFooter.css';

class NavFooterLink extends React.Component
{
	render()
	{
		return (
			<a className="NavFooterLink" href={this.props.url}>
				<i className={this.props.icon}></i> {this.props.title}
			</a>
		);
	}
}

class NavFooter extends React.Component
{
	render()
	{
		var serviceLinks = [];
		serviceLinks.push("3D Animation");
		serviceLinks.push("3D Modelling");
		serviceLinks.push("3D Rigging");
		serviceLinks.push("Game Design");
		serviceLinks.push("Gameplay Programming");
		serviceLinks.push("Gameplay Testing");
		serviceLinks.push("Tool Programming");

		serviceLinks = serviceLinks.map((link, index) =>
		{
			return <NavFooterLink key={index} url="#" icon="fas fa-angle-right" title={link} />;
		});

		var legalLinks = [];
		legalLinks.push({"title":"Terms and Conditions","url":"/tos/"});
		legalLinks.push({"title":"Privacy Policy","url":"/privacy/"});
		legalLinks.push({"title":"Cookie Policy","url":"/privacy/"});

		legalLinks = legalLinks.map((link, index) =>
		{
			return <NavFooterLink key={index} url={link.url} icon="fas fa-angle-right" title={link.title} />;
		});

		return (
			<div className="NavFooter">
				<div className="row">
					<h3><i className="fas fa-info-circle"></i> Information</h3>
					<NavFooterLink url="/" icon="fas fa-home" title="Home" />
					<NavFooterLink url="/about/" icon="fas fa-info-circle" title="About Us" />
					<NavFooterLink url="/blog/" icon="fas fa-newspaper" title="Visit our Blog" />
					<NavFooterLink url="/contact/" icon="fas fa-envelope" title="Contact Us" />
				</div>
				<div className="row">
					<h3><i className="fas fa-briefcase"></i> Services</h3>
					{serviceLinks}
				</div>
				<div className="row">
					<h3><i className="fas fa-balance-scale"></i> Legal</h3>
					{legalLinks}
				</div>
			</div>
		);
	}
}
export default NavFooter;