import React from 'react';
import './FooterApp.css';
import NavFooter from './components/NavFooter/NavFooter';
import Footer from './components/Footer/Footer';

class FooterApp extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
		};
	}

	// componentDidMount()

	render()
	{
		return (
			<div className="FooterApp">
				<NavFooter />
				<Footer />
			</div>
		);
	}
}
export default FooterApp;