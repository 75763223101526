import React from 'react';
import './Footer.css';


function FooterSocialLink(props)
{
	return (
		<a className="FooterSocialLink" target="_blank" rel="noreferrer" href={props.url}>
			<i className={props.icon}></i>
		</a>
	);
}

class Footer extends React.Component
{
	render()
	{
		return (
			<div className="Footer">
				<div className="row about">
					<div className="logo">
						<a href="https://blazinggatorgames.ca/">
							<img src="https://blazinggatorgames.ca/images/BlazingGatorGamesTitle.png" alt="gator logo" />
						</a>
					</div>
					<p>
						<span className="url">Check out our social media</span>.
						We create remarkable updates and post them online for everyone to see!
					</p>
					<div className="socials">
						<FooterSocialLink url="https://www.facebook.com/Blazing-Gator-Games-107759121667063" icon="fab fa-facebook-f" />
						<FooterSocialLink url="https://twitter.com/blazinggator" icon="fab fa-twitter" />
						<FooterSocialLink url="https://www.instagram.com/blazinggatorgames/" icon="fab fa-instagram" />
						<FooterSocialLink url="https://www.youtube.com/channel/UConRtl5hjXsSVZX78ZtzW3Q/about" icon="fab fa-youtube" />
					</div>
				</div>
				<div className="row review">
					<p>&quot;I had a really good experience with these gators.
						They were really soft, cuddly and very playful.
						They radiated love and pure joy.
						I would absolutely play with them again.&quot;</p>
					<p>- A loyal customer</p>
				</div>
				<div className="row contact">
					<p>
					<i className="fas fa-map-location"></i> Niagara Falls, Ontario<br/>
						Canada<br/>
						<a href="tel:+12893025878" className="number">
							<i className="fas fa-phone"></i> +1 (289) 302-5878
						</a>
						<a href="mailto:contact@blazinggatorgames.ca" className="number">
							<i className="fas fa-envelope"></i> contact@blazinggatorgames.ca
						</a>
					</p>
				</div>
			</div>
		);
	}
}
export default Footer;